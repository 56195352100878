
<template>
  <v-container class="pa-10">
    <v-data-table
      :headers="headers"
      :items="products"
      sort-by="id"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:top >
        <v-toolbar flat color="white" class="mb-5">
          <v-toolbar-title>My Products</v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="mt-5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                filled
                rounded
                dense
                hide-details
                
              ></v-text-field>
            </div>
          <v-divider class="mx-4" inset vertical></v-divider>
          
          <v-dialog v-model="dialog" max-width="800px">
            
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                to="/supportdashboard/pages/addproduct"
                class="mt-5"
              >
                <v-icon color="white">mdi-plus</v-icon>Add product
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        v-model="editedItem.category_id"
                        label="category"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        v-model="editedItem.sub_category_id"
                        label="Sub category"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        v-model="editedItem.sub_sub_category_id"
                        label="Sub Sub category"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.title"
                        label="Title"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.brand"
                        label="Product Brand"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.description"
                        label="Product Description"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.key_features"
                        label="Product key features"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.quantity"
                        label="Product Quantity"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                      <v-text-field
                        v-model="editedItem.price"
                        label="Product Price"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.point"
                        label="Product Point"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.size"
                        label="Available Product sizes"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.weight"
                        label="Product Weight"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.color"
                        label="Product Color"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.unit"
                        label="Product Unit"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.discount_type"
                        label="Discount Type"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.amount"
                        label="Discount Amount "
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-datetime-picker
                        label="Start Date"
                        v-model="editedItem.start_date"
                      ></v-datetime-picker>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-datetime-picker
                        label="End Date"
                        v-model="editedItem.end_date"
                      ></v-datetime-picker>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <template>
                        <v-file-input
                          v-model="editedItem.image"
                          color="deep-purple accent-4"
                          counter
                          label="Upload Your Image"
                          multiple
                          outlined
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >

                            <span
                              v-else-if="index === 2"
                              class="overline grey--text text--darken-3 mx-2"
                              >+{{ files.length - 2 }} File(s)</span
                            >
                          </template>
                        </v-file-input>
                      </template>
                    </v-col>
                  </v-row>
                  <v-checkbox
                    v-model="editedItem.checkbox"
                    :error-messages="checkboxErrors"
                    label="Check if your product property is private"
                    @change="$v.checkbox.$touch()"
                    @blur="$v.checkbox.$touch()"
                  ></v-checkbox>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="saveEdit">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
        <template v-slot:item.specification="{ item }">
          <v-icon color="green" class="mr-2" @click="viewSpecification(item)">mdi-plus</v-icon>
        
        <!-- <v-icon small @click="viewSpecification(item)">mdi-view-list</v-icon> -->
      </template>
      <template v-slot:no-data></template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data: () => ({
     search: "",
    dialog: false,
    headers: [
      {
        text: "product ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Title", value: "title" },
      { text: "Brand", value: "brand" },
      { text: "Quantity", value: "quantity" },
      { text: "Date", value: "date" },
      { text: "Actions", value: "actions", sortable: false },
      { text: "Specification", value: "specification", sortable: false },
    ],
    products: [],
    contact: {},
    editedIndex: -1,
    editedItem: {
      title: "",
      category_id: "",
      brand: "",
      image: "",
      description: "",
      key_features: "",
      quantity: "",
      properties: "",
      point: "",
      price: "",
      size: "",
      unit: "",
      weight: "",
      color: "",
      discount_type: "",
      amount: "",
      start_date: "",
      end_date: "",
    },
    defaultItem: {
      title: "",
      category_id: "",
      brand: "",
      image: "",
      description: "",
      key_features: "",
      quantity: "",
      properties: "",
      point: "",
      price: "",
      size: "",
      unit: "",
      weight: "",
      color: "",
      discount_type: "",
      amount: "",
      start_date: "",
      end_date: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    initialize() {
      axios.get("product/products_detail/").then((response) => {
     
        this.products = response.data;
      });
    },

    editItem(item) {
      this.editedItem = item;
      this.dialog = true;
    },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios.post(`product/delete_product/${item.id}/`).then((response) => {
          this.products = this.products.filter(
            (contact) => contact.id !== item.id
          );
        });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    saveEdit() {
      axios
        .post(`prodct/modify_product/${this.editedItem.id}/`, this.editedItem)
        .then((response) => {
          this.dialog = false;
        });
    },
    viewSpecification(item) {
    
      this.$router.push({
        path: "/supportdashboard/pages/specification",
        query: { id: item.id },
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>


